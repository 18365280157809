import { AuthenticationScheme, IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { useEffect, useMemo, useState } from "react";
import { AZURE_SCOPES } from "../services/auth-config";

const getNewTokenAsync = async (instance: IPublicClientApplication, refresh: boolean) => {
    if (instance.getActiveAccount() === null) {
        let accs = instance.getAllAccounts();
        if (accs.length > 0) {
            instance.setActiveAccount(accs[0]);
        }
    }

    let r = await instance.acquireTokenSilent({ scopes: AZURE_SCOPES, authenticationScheme: AuthenticationScheme.BEARER, forceRefresh: refresh });
    return r.idToken;
}

/**
 * A hook that gets a token for the user silently.
 * @returns A token fot the user
 */
export const useToken = () => {
    const { instance } = useMsal();

    const [token, setToken] = useState<string | null>(sessionStorage.getItem("__jwt_token"));
    const [jwt, setJwt] = useState<JwtPayload | null>(token ? jwt_decode(token) : null);

    return {
        getToken: () => {
            if ((jwt?.exp! * 1000) < Date.now() || !token || !jwt) {
                getNewTokenAsync(instance, true).then(t => {
                    setToken(t);
                    setJwt(jwt_decode(t));
                    sessionStorage.setItem("__jwt_token", t);
                })
            }

            return token ? AuthenticationScheme.BEARER + " " + token : undefined;
        }
    };
}