import { Campaign, CampaignUpdateDto } from "../models/campaign";
import { Company, Position } from "../models/company";
import { Employee } from "../models/employee";
import { SkillProfile, SkillProfileUpdateDto } from "../models/skill-profiles";
import { User } from "../models/user";

export const GetIsAdminAsync = (token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/user/isadmin`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d.isAdmin as boolean);
}

export const GetCompaniesAsync = (token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/user/companies`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d as Company[]);
}

export const GetCampaignsAsync = (companyId: number, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/campaigns?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d?.d?.results as Campaign[] | undefined ?? d as Campaign[]);
}

export const GetSkillProfilesAsync = (companyId: number, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/skillProfiles?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d?.d?.results as SkillProfile[] | undefined ?? d as SkillProfile[]);
}

export const AddEmployee = (employee: Employee, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/employee`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(employee)
    });
}

export const UpdateEmployee = (employee: Employee, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/employee`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify(employee)
    });
}

export const UploadOptishiftData = (companyId: number, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/employee/upload?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: 'post',
    });
}

export const GetCompanyEmployees = (companyId: number, token: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/employees?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d as Employee[]);
}

export const UpdateCampaignAsync = (token: string, campaignUpdate: CampaignUpdateDto) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/campaign/update`, {
        headers: { 'Authorization': token },
        method: "POST",
        body: JSON.stringify(campaignUpdate)
    });
}

export const UpdateSkillProfileAsync = (token: string, skillProfileUpdate: SkillProfileUpdateDto) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/skillProfile/update`, {
        headers: { 'Authorization': token },
        method: "POST",
        body: JSON.stringify(skillProfileUpdate)
    });
}

export const GetUsersAsync = (token: string, all: boolean = false) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/users?all=${all}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d as User);
}

export const RegisterUserAsync = (token: string, user: User) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/user`, {
        headers: { 'Authorization': token },
        method: 'post',
        body: JSON.stringify(user)
    });
}

export const UpdateUserAsync = (token: string, user: User) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/user`, {
        headers: { 'Authorization': token },
        method: 'put',
        body: JSON.stringify(user)
    });
}

export const DeleteUserAsync = (token: string, email: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/user?email=${email}`, {
        headers: { 'Authorization': token },
        method: 'delete'
    });
}

export const GetCompanyPositionsAsync = (token: string, companyId: number) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/company/positions?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json())
        .then(d => d as Position[]);
}

export const DownloadFileAsync = (token: string, filename: string) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/transaction/download?filename=${encodeURI(filename)}`, {
        headers: { 'Authorization': token },
    });
}

export const GetTransactionsAsync = (token: string, companyId: number) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/company/transactions?companyId=${companyId}`, {
        headers: { 'Authorization': token }
    })
        .then(r => r.json());
}

export const AddPositionToCompanyAsync = (token: string, position: Position, companyId: number) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/company/position?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: "post",
        body: JSON.stringify(position),
    });
}

export const UpdatePositionNameAsync = (token: string, position: Position, companyId: number) => {
    return fetch(`${process.env.REACT_APP_API_URI}/api/company/positions?companyId=${companyId}`, {
        headers: { 'Authorization': token },
        method: "put",
        body: JSON.stringify([position] as Position[]),
    });
}