import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Button, CheckBox, Popup } from "devextreme-react";
import { DataGrid, Column, Toolbar, Item, Button as DgButton } from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";
import CustomStore from "devextreme/data/custom_store";
import dxDataGrid from "devextreme/ui/data_grid";
import notify from "devextreme/ui/notify";
import { FunctionComponent, useState } from "react";
import { useToken } from "../../../hooks/useToken";

import './Users.css'
import { RegisterUser } from "./RegisterUser";
import { DeleteUserAsync, GetCompaniesAsync, GetUsersAsync, UpdateUserAsync } from "../../../services/http-service";

export const Users: FunctionComponent = () => {
    let token = useToken();

    const userCompanies = new CustomStore({
        loadMode: "processed",
        key: "id",
        load: (_) => {
            if (!token.getToken()) {
                return Promise.resolve([]);
            }
            return GetCompaniesAsync(token.getToken()!);
        }
    });

    let companiesDataGrid: dxDataGrid | undefined;
    let selectedUserMail: string | undefined;

    let [showAll, setShowAll] = useState<boolean>(false);

    const dataStore = new CustomStore({
        loadMode: "raw",
        key: "email",
        load: (_) => {
            if (!token.getToken()) {
                return Promise.resolve([]);
            }

            return GetUsersAsync(token.getToken()!)
                .catch(err => {
                    console.error(err);
                    notify("There was an error while fetching user data", "error", 2500)
                    return [];
                });
        },
        update: async (u, v) => {
            if (!token.getToken()) {
                return Promise.resolve([]);
            }

            const result = await UpdateUserAsync(token.getToken()!, { email: u, isAdmin: v.isAdmin, companies: v.companies, hasAccessToEvaluations: v.hasAccessToEvaluations })

            if (result.ok) {
                notify("User updated!", "success", 2500)
                return u;
            } else {
                result.json().then(d => notify(d.message, "error", 2500))
            }
        },
        remove: async (u) => {
            if (!token.getToken()) {
                return Promise.resolve([]);
            }

            const result = await DeleteUserAsync(token.getToken()!, u);

            if (result.ok) {
                notify("User deleted!", "success", 2500)
                return u;
            } else {
                result.json().then(d => notify(d.message, "error", 2500))
            }
        }
    });

    let [registerPopupVisible, setRegisterPopupVisible] = useState<boolean>(false);

    return (
        <div className="camp-table">
            <AuthenticatedTemplate>
                <Popup
                    visible={registerPopupVisible}
                    onVisibleChange={(v) => {
                        if (!v)
                            setRegisterPopupVisible(false);
                    }}
                >
                    <RegisterUser userCompanies={userCompanies} />
                </Popup>
                <DataGrid
                    id='gridContainer'
                    showBorders={true}
                    dataSource={dataStore}
                    pager={{ allowedPageSizes: [10, 20, 50], visible: true, displayMode: "adaptive", showPageSizeSelector: true }}
                    filterRow={{ visible: true }}
                    editing={{ allowUpdating: true, allowDeleting: true, mode: "cell", useIcons: true }}
                >
                    <Toolbar>
                        <Item location="before">
                            <Button icon="add"
                                text="Register User" onClick={(_) => {
                                    setRegisterPopupVisible(true)
                                }} />
                        </Item>
                        <Item location="before">
                            <CheckBox text="Show All" name="show-all" onOptionChanged={(e) => setShowAll(e.value)} value={showAll} />
                        </Item>
                    </Toolbar>
                    <Column type="buttons" width={50}>
                        <DgButton name="delete" />
                    </Column>
                    <Column dataField="email" caption="User Email" />
                    <Column dataField="isAdmin" caption="Is Admin" width={200} />
                    <Column dataField="hasAccessToEvaluations" caption="Access to Evaluations" width={200} />
                    <Column dataField="companies" caption="Companies"
                        calculateDisplayValue={(e: any) => e.companies.map((c: any) => c.companyName).join(", ")}
                        editCellRender={(d) => {
                            return (
                                <DropDownBox>
                                    <DataGrid
                                        selection={{ mode: "multiple" }}
                                        key="companyName"
                                        dataSource={userCompanies}
                                        onContentReady={async (e) => {
                                            const keys = d.data.companies.map((c: any) => c.id);
                                            await e.component?.selectRows(keys, true);
                                        }}
                                        onInitialized={(e) => {
                                            companiesDataGrid = e.component;
                                            selectedUserMail = d.data.email
                                        }}
                                    >
                                        <Toolbar>
                                            <Item location="before">
                                                <Button icon="save"
                                                    text="Save" onClick={async (_) => {
                                                        const values = (await companiesDataGrid?.getSelectedRowsData());
                                                        const u = await dataStore.update(selectedUserMail, { companies: values })
                                                        if (u) {
                                                            dataStore.push([{
                                                                type: "update",
                                                                key: selectedUserMail,
                                                                data: { companies: values }
                                                            }])
                                                        }
                                                    }} />
                                            </Item>
                                        </Toolbar>
                                        <Column dataField="companyName" />
                                    </DataGrid>
                                </DropDownBox>
                            )
                        }}
                    />
                </DataGrid>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                Please sign-in to see this page.
            </UnauthenticatedTemplate>
        </div >
    );
}